import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import './Solutions.css';
import SolutionsWidget from './SolutionsWidget';
import { Row, Col } from 'reactstrap';
import BoxWidget from '../global/BoxWidget';

import WidgetOneImage from '../../assets/solutions/tile-crm-customer-relationship-management-sydney.jpg'
import WidgetTwoImage from '../../assets/solutions/tile-web-solutions-sydney.jpg'
import WidgetThreeImage from '../../assets/solutions/tile-smart-phone-mobile-apps-digital.jpg'
import WidgetFourImage from '../../assets/solutions/tile-infrastructure.jpg'

export default () => (
    <StaticQuery
        query={graphql`
        query SolutionContentQuery {
          allStrapiSolutionspage {
            edges {
              node {
                ContentHeading
                ContentBody
                WidgetOneTitle
                WidgetOneInfo
                WidgetTwoTitle
                WidgetTwoInfo
                WidgetThreeTitle
                WidgetThreeInfo
                WidgetFourTitle
                WidgetFourInfo
              }
            }
          }
        }
      `}
        render={data => (

            <div>
                <section>
                    <div className="container-fluid">
                        <Row>
                            <Col lg="8" className="pr-lg-0 sb-left">
                                <div className="d-flex pt-5 pb-5">
                                    <div className="pl">
                                        {data.allStrapiSolutionspage.edges.map(document => (
                                            <h3 className="sec-header-sub">
                                                {document.node.ContentHeading}
                                            </h3>
                                        ))}


                                        {data.allStrapiSolutionspage.edges.map(document => (
                                            <p className="sec-para">
                                                {document.node.ContentBody}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                <div className="row pl pb-lg-5 justify-content-between s-widget-holder">
                                    {data.allStrapiSolutionspage.edges.map(document => (

                                        <SolutionsWidget
                                            ServiceTitle={document.node.WidgetOneTitle}
                                            ServiceHeaderImg={WidgetOneImage}
                                            Serviceinfo={document.node.WidgetOneInfo}
                                        />
                                    ))}

                                    {data.allStrapiSolutionspage.edges.map(document => (

                                        <SolutionsWidget
                                            ServiceTitle={document.node.WidgetTwoTitle}
                                            ServiceHeaderImg={WidgetTwoImage}
                                            Serviceinfo={document.node.WidgetTwoInfo}
                                        />
                                    ))}

                                    {data.allStrapiSolutionspage.edges.map(document => (

                                        <SolutionsWidget
                                            ServiceTitle={document.node.WidgetThreeTitle}
                                            ServiceHeaderImg={WidgetThreeImage}
                                            Serviceinfo={document.node.WidgetThreeInfo}
                                        />
                                    ))}

                                    {data.allStrapiSolutionspage.edges.map(document => (

                                        <SolutionsWidget
                                            ServiceTitle={document.node.WidgetFourTitle}
                                            ServiceHeaderImg={WidgetFourImage}
                                            Serviceinfo={document.node.WidgetFourInfo}
                                        />
                                    ))}

                                </div>

                            </Col>
                            <Col lg="4" className="p-lg-5 sb-right mb-5">
                                <BoxWidget />
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        )}
    />
);

