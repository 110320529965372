import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./Solutions.css";
import SolutionsImage from "../../assets/solutions/valor-digital-solutions-sydney.jpg";
import GenericHeader from "../templates/genericHeader";

export default () => (
    <StaticQuery
        query={graphql`
      query SolutionsHeadingQuery {
        allStrapiSolutionspage {
          edges {
            node {
              PageHeading,
              PageHeadingBlurb
            }
          }
        }
      }
    `}
        render={data => (
            <div className="tabarja-about">


                <GenericHeader
                    Heading={data.allStrapiSolutionspage.edges.map(document => (
                        <div>{document.node.PageHeading}</div>
                    ))}
                    headerInfo={data.allStrapiSolutionspage.edges.map(document => (
                        <div>{document.node.PageHeadingBlurb}</div>
                    ))}
                    infoHeaderImage={SolutionsImage}
                    infoHeaderImageAlt={"Tabarja Digital Solutions"}
                />
            </div>
        )}
    />
);