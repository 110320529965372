import React from "react"

import Layout from "../components/global/layout"
import SolutionsHeader from '../components/solutions/SolutionsHeader'
import SolutionsContent from '../components/solutions/SolutionsContent'

import GetInTouchToday from '../components/global/GetInTouchToday';
import SEO from "../components/global/seo"
import {graphql, StaticQuery} from "gatsby";


export default () => (
    <StaticQuery
        query={graphql`
      query SolutionsPageQuery {
        allStrapiSolutionspage {
          edges {
            node {
              MetaDescription
            }
          }
        }
      }
    `}
        render={data => (
            <Layout>
                {data.allStrapiSolutionspage.edges.map(document => (
                    <SEO
                        title="Solutions" keywords={[`Digital Solutions`, `IT Solutions`, `AEM`,'Dynamics 365']}
                        description={document.node.MetaDescription}
                    />
                ))}
                <SolutionsHeader />
                <SolutionsContent />
                <GetInTouchToday />
            </Layout>
        )}
    />
);